<template>
  <div class="home pa-6">
		<h1>Todo Page</h1>
	</div>
</template>

<script>
  export default {
    name: 'Home'
  }
</script>
